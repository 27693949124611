import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { SlArrowUp } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useSearchParams } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "./App.css";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import InitialPopupModal from "./components/Modal/InitialPopupModal";
import KnowInsideOutModal from "./components/Modal/KnowInsideOutModal";
import PhoneNumberModal from "./components/Modal/PhoneNumberModal";
import { popup_app_image, popup_website_image } from "./components/imageArrays";
import { isLogoutUser } from "./features/auth/authSlice";
import {
  initialPopupModalShow,
  phoneNumberModalHide,
  phoneNumberModalShow,
} from "./features/modal/modalSlice";
import { auth } from "./firebase/Firebase";
import useAuthUser from "./hooks/useAuthUser";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import AllBrandPage from "./pages/Brand/AllBrandPage";
import BrandPage from "./pages/Brand/BrandPage";
import IndividualBrandPage from "./pages/Brand/IndividualBrandPage";
import YouMayLikeBrandPage from "./pages/Brand/YouMayLikeBrandPage";
import ContactUsPage from "./pages/Common/ContactUsPage";
import ShareAndJoyPage from "./pages/Common/ShareAndJoyPage";
import TermsAndConditionPage from "./pages/Common/TermsAndConditionPage";
import UserDasboard from "./pages/User/UserDashboard";

import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import { campaignPageDes } from "./Data/descriptionData";
import TestAccordion from "./components/Accordion/Accordion";
import LoginModal from "./components/LoginRegi/LoginModal";
import Modal from "./components/LoginRegi/Modal";
import { useGetVisitorTrackingQuery } from "./features/visitors/visitorApiSlice";
import useDeviceLogs from "./hooks/useDeviceLogs";
import useUserTracking from "./hooks/useUserTracking";
import ActivationPage from "./pages/Activation/ActivationPage";
import LoginPageModal from "./pages/Auth/LoginPageModal";
import BlogPage from "./pages/Blog/BlogPage";
import LearnAboutCashbackPage from "./pages/Blog/LearnAboutCashbackPage";
import LearnAboutEarnPage from "./pages/Blog/LearnAboutEarnPage";
import AllBrandCategoriesPage from "./pages/Brand/AllBrandCategoriesPage";
import CategoryWiseBrandsPage from "./pages/Brand/CategoryWiseBrandsPage";
import CashbackRedirectingPage from "./pages/Common/CashbackRedirectingPage";
import CustomerSupportPage from "./pages/Common/CustomerSupportPage";
import DownloadAppPage from "./pages/Common/DownloadAppPage";
import Error404Page from "./pages/Common/Error404Page";
import FAQPage from "./pages/Common/FAQPage";
import HomePage from "./pages/Common/HomePage";
import PrivacyPolicyPage from "./pages/Common/PrivacyPolicyPage";
import ShareAndEarnPage from "./pages/Common/ShareAndEarnPage";
import CashbackProductPage from "./pages/Product/CashbackProductPage";
import CommissionProductPage from "./pages/Product/CommissionProductPage";
import { PolicePrivilege } from "./pages/SpecificBrandLandingPage/PolicePrivilege";
import { ShebaLandingPage } from "./pages/SpecificBrandLandingPage/ShebaLandingPage";
import CashBackBalancePage from "./pages/User/Balance/CashBackBalancePage";
import EarningBalancePage from "./pages/User/Balance/EarningBalancePage";
import CashbackFavouriteProductsPage from "./pages/User/FavouriteProducts/CashbackFavouriteProductsPage";
import EarningFavouriteProductsPage from "./pages/User/FavouriteProducts/EarningFavouriteProductsPage";
import CasbackLeaderBoardPage from "./pages/User/Leaderboard/CasbackLeaderBoardPage";
import EarningLeaderBoardPage from "./pages/User/Leaderboard/EarningLeaderBoardPage";
import ReferralLeaderBoardPage from "./pages/User/Leaderboard/ReferralLeaderBoard";
import MakeProfitLinkPage from "./pages/User/MakeProfitLinkPage";
import MyReferallasPage from "./pages/User/MyReferallasPage";
import OnlineWorkShopPage from "./pages/User/OnlineWorkShopPage";
import PersonalInfoPage from "./pages/User/PersonalInfoPage";
import CashbackCalculationPage from "./pages/User/ProfitCalculation/CashbackCalculationPage";
import EarningCalculationPage from "./pages/User/ProfitCalculation/EarningCalculationPage";
import UpdatePasswordPage from "./pages/User/UpdatePasswordPage";
import UpdateProfilePage from "./pages/User/UpdateProfilePage";
import UserActivityLogPage from "./pages/User/UserActivityLogPage";

/**This code for Google Analytics- Start */
// ReactGA.initialize("UA-264525809-1");
ReactGA.initialize("G-22G34L49P0");
/**This code for Google Analytics- End */

function App() {
  const { isModalOpen } = useSelector((state) => state?.loginModalShow);

  const dispatch = useDispatch();
  const user = useAuthUser();
  // user tracking
  const trackingUser = useUserTracking();

  // device tracking
  useDeviceLogs();

  const [isIpPresent, setIsIpPresent] = useState();
  // const [isModalOpen, setIsModalOpen] = useState(true);
  // const { shop } = useSelector((state) => state?.shop);

  // removing browser caches
  // useEffect(() => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  // }, []);

  /**This code for Google Analytics- Start */
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  /**This code for Google Analytics- End */

  // remove cookie
  useEffect(() => {
    const access_token = Cookies?.get("ayykori");
    if (!access_token) {
      Cookies.remove("ayykori");
      auth.signOut();
      dispatch(isLogoutUser());
    }
  }, [dispatch]);

  // show or notshow phone number modal
  useEffect(() => {
    if (user && !user?.phone) {
      dispatch(phoneNumberModalShow());
    } else {
      dispatch(phoneNumberModalHide());
    }
  }, [user, dispatch]);

  // showing the initial image popup
  useEffect(() => {
    const item = localStorage.getItem("welcome_popup");
    if (user && user?.phone && !item) {
      dispatch(initialPopupModalShow());
    }
  }, [user, dispatch]);

  // visitor
  // visitor
  useEffect(() => {
    const localIP = localStorage?.getItem("ip");

    if (localIP) {
      const ip = JSON.parse(localIP);
      setIsIpPresent(ip.ip);
    }
  }, []);

  const {
    data: visitor,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetVisitorTrackingQuery(isIpPresent, {
    skip: !isIpPresent,
    refetchOnReconnect: true,
  }) || {};

  const [query] = useSearchParams();
  const refferalCode = query?.get("active");
  const policeCode = query?.get("police");

  return (
    <>
      {/* <Connect /> */}
      <KnowInsideOutModal />
      <PhoneNumberModal />
      <InitialPopupModal
        websiteImages={popup_website_image}
        appImages={popup_app_image}
      />
      <Toaster />
      {window.innerWidth >= 764 && (
        <ScrollToTop
          smooth={true}
          component={<SlArrowUp />}
          top={80}
          viewBox="0 0 256 256"
          width="15px"
          height="15px"
          style={{
            color: "#ffffff",
            borderRadius: "50%",
            backgroundColor: "#11998E",
            fontSize: "18px",
            display: "flex",
            padding: "2px",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "lighter",
          }}
        />
      )}{" "}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          height={500}
          width={840}
          children={<LoginModal />}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            refferalCode ? (
              <ShebaLandingPage data={campaignPageDes[0]} />
            ) : policeCode ? (
              <PolicePrivilege data={campaignPageDes[2]} />
            ) : (
              <HomePage />
            )
          }
        />

        {/* <Route path="/under-maintanance" element={<UnderMaintanancePage />} /> */}
        {/* <Route path={`/shop/${shop}/*`} element={<ShopApp />} /> */}
        <Route
          path="cashback-activated/:id"
          element={<CashbackRedirectingPage />}
        />
        <Route path="/test-accordion" element={<TestAccordion />} />
        <Route
          path="/login"
          element={
            <PublicRoute path={"/"}>
              <LoginPageModal source={"page"} />
            </PublicRoute>
          }
        />
        {/* </Route> */}
        {/* <Route path="/" element={<PublicRoute path={"/"} />}> */}
        <Route
          path="/login"
          element={
            <PublicRoute path={"/"}>
              {/* <Login /> */}
              <LoginPageModal />
            </PublicRoute>
          }
        />
        {/* </Route> */}
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        {refferalCode ? (
          <Route path="/brand-partner" element={<ShebaLandingPage />} />
        ) : (
          <Route path="/" element={<HomePage />} />
        )}

        <Route
          path="/api/v1/user/password/reset/:token"
          element={<ResetPasswordPage />}
        />
        <Route path="/app-download" element={<DownloadAppPage />} />
        <Route path="/affiliate/:title" element={<ActivationPage />} />
        {/* <Route path="/search/:searchVal" element={<SearchPage/>}/> */}

        {/* user route start */}
        {/* <Route
          path="/"
          element={
            <PrivateRoute allowedRoles={["1999", "2001"]} path={"/login"} />
          }
        > */}
        <Route
          path="/my-account"
          element={
            <PrivateRoute allowedRoles={["1999", "2001"]} path={"/login"}>
              <UserDasboard />
            </PrivateRoute>
          }
        >
          <Route path="update-account" element={<UpdateProfilePage />} />
          <Route path="make-affiliate-link" element={<MakeProfitLinkPage />} />
          <Route path="user-activity-log" element={<UserActivityLogPage />} />
          <Route index element={<CashBackBalancePage />} />
          <Route path="cashback-balance" element={<CashBackBalancePage />} />
          <Route path="earning-balance" element={<EarningBalancePage />} />
          <Route
            path="cashback-calculation"
            element={<CashbackCalculationPage />}
          />
          <Route
            path="earning-calculation"
            element={<EarningCalculationPage />}
          />
          <Route path="online-workshop" element={<OnlineWorkShopPage />} />
          <Route path="my-referrals" element={<MyReferallasPage />} />
          <Route
            path="cashback-leaderboard"
            element={<CasbackLeaderBoardPage />}
          />
          <Route
            path="earning-leaderboard"
            element={<EarningLeaderBoardPage />}
          />

          {/* <Route
            path="cashback-favourite-brands"
            element={<CashbackFavouriteBrandsPage />}
          />
          <Route
            path="earning-favourite-brands"
            element={<EarningFavouriteBrandsPage />}
          /> */}

          <Route
            path="cashback-favourite-products"
            element={<CashbackFavouriteProductsPage />}
          />
          <Route
            path="earning-favourite-products"
            element={<EarningFavouriteProductsPage />}
          />
          <Route
            path="referral-leaderboard"
            element={<ReferralLeaderBoardPage />}
          />
          <Route path="personal-info" element={<PersonalInfoPage />} />
          <Route path="update-account" element={<UpdateProfilePage />} />
          <Route path="update-password" element={<UpdatePasswordPage />} />
        </Route>
        {/* </Route> */}

        {/* user route end */}

        {/* campaign route start */}
        {/* <Route path="campaign" element={<CampaignPage />} /> */}

        {/* ----------------------------campaign content start----------------------------- */}

        {/* <Route
          path="campaign"
          element={<SecondCampaignPage data={campaignPageDes[1]} />}
        /> */}
        {/* ----------------------------campaign content end----------------------------- */}
        {/* campaign route end */}

        {/* brand route  start */}
        <Route path="brands/:title" element={<BrandPage />} />
        <Route
          path="brands/brands-subbrands/:title"
          element={<AllBrandPage />}
        />
        <Route
          path="brands/you_may_like/:id"
          element={<YouMayLikeBrandPage />}
        />
        {/* <Route
          path="/"
          element={
            <PrivateRoute allowedRoles={["1999", "2001"]} path={"/login"} />
          }
        > */}
        <Route
          path="brand/:type/:id"
          element={
            // <PrivateRoute allowedRoles={["1999", "2001"]} path={"/login"}>
            <IndividualBrandPage />
            // </PrivateRoute>
          }
        />
        {/* </Route> */}
        <Route path="brands/categories" element={<AllBrandCategoriesPage />} />
        <Route
          path="brands/category/:categoryname"
          element={<CategoryWiseBrandsPage />}
        />
        <Route
          path="brands/share-happiness-&-joy"
          element={<ShareAndJoyPage />}
        />
        {/* <Route path="brands/trending" element={<TrendingPage />} /> */}
        {/* brand route  end */}
        {/* product route  start */}
        <Route
          path="affiliate/best-deals-to-share"
          element={<CommissionProductPage />}
        />
        <Route
          path="cashback-products/:categoryname"
          element={<CashbackProductPage />}
        />
        {/* product route  end */}

        <Route path="affiliate" element={<ShareAndEarnPage />} />
        <Route
          path="know-inside-out/learn-about-cashback"
          element={<LearnAboutCashbackPage />}
        />
        <Route
          path="know-inside-out/learn-about-earn"
          element={<LearnAboutEarnPage />}
        />

        {/* blog page */}
        <Route path="/blogs/:blogtype/:type" element={<BlogPage />} />

        {/* help section route all dropdown start*/}
        <Route path="contact" element={<ContactUsPage />} />
        <Route path="customer-support" element={<CustomerSupportPage />} />
        {/* <Route path="customer-support/:supportName" element={<SupportFormPage />} /> */}
        <Route path="faqs" element={<FAQPage />} />
        {/* <Route path="how-to-use-ayykori" element={<HowToUseAyykoriPage />} /> */}
        {/* help section route all dropdown end*/}

        {/* footer pages start */}
        <Route
          path="terms-and-conditions"
          element={<TermsAndConditionPage />}
        />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="*" element={<Error404Page />} />
        {/* footer pages end */}
      </Routes>
    </>
  );
}

export default App;
