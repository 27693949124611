import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { BiSolidTimeFive } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";
import { FaEnvelope, FaPhone, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import tictok from "../../Assets/images/icon/tictok.svg";
import { footerData } from "../../Data/footerData";
import useAuth from "../../hooks/useAuth";
const Footer = () => {
    const isAuth = useAuth();
    /*For PWA Start - Please don't touch this code*/
    const [, setDeferredPrompt] = useState(null);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the default installation prompt
            e.preventDefault();

            // Store the deferred prompt for later use
            setDeferredPrompt(e);
        });
    }, []);

    // <button onClick={handleInstall}>Install</button>
    /*For PWA End - Please don't touch this code*/
    return (
        <div className="bg-gray-700 ">
            <div className="w-full px-3 md:px-[55px] flex flex-col justify-center items-center py-8 max-w-[1632px] mx-auto">
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                    <div className="">
                        <h1 className="mb-2 md:mb-4 text-white text-base md:text-lg font-medium">
                            Contact Info
                        </h1>
                        {/* <div className="flex items-start gap-x-2">
              <img src={ayykori_qr} alt="" className="w-20 rounded-md" />
              <div>
                <img src={logo} alt="" className="w-8 rounded-md" />
                <Link
                  to="/app-download"
                  // onClick={handleInstall}
                  className="text-white text-sm md:text-base hover:cursor-pointer"
                >
                  Download App
                </Link>
              </div>
            </div> */}

                        <div className="flex flex-col gap-y-2 md:gap-y-3 mt-2">
                            {/* <span className="flex font-normal items-center gap-x-2 text-sm text-gray-300">
                <FaLocationArrow />
                TA-39/1, Gulshan - Badda Link Road, Dhaka - 1212
              </span> */}
                            <span className="flex font-normal items-center gap-x-2 text-xs md:text-sm text-gray-300">
                                <FaPhone />
                                +8809678221460
                            </span>
                            <span className="flex font-normal items-center gap-x-2 text-xs md:text-sm text-gray-300">
                                <BiSolidTimeFive />
                                Everyday 10am-6pm [except Fridays]
                            </span>
                            <span className="flex font-normal items-center gap-x-2 text-xs md:text-sm text-gray-300">
                                <FaEnvelope />
                                contact@ayykori.net
                            </span>

                            <Link
                                to="https://wa.me/1620794240?text=Thank%20you%20for%20choosing%20Ayykori.%20We’re%20here%20for%20you!"
                                className="bg-green-500 text-white py-1 px-2 md:py-2 md:px-3 w-fit rounded-lg shadow-lg hover:bg-green-600 transition-all flex items-center justify-center space-x-1"
                                target="_blank"
                            >
                                <FaWhatsapp className="text-2xl lg:text-xl" />
                                <span className="text-xs md:text-sm">
                                    Chat on WhatsApp
                                </span>
                            </Link>
                        </div>
                    </div>
                    {footerData &&
                        footerData?.map((data, index) => (
                            <div key={index}>
                                <h1 className="mb-2 md:mb-4 text-white text-base md:text-lg font-medium">
                                    {data?.title}
                                </h1>
                                <div className="flex flex-col gap-y-1 md:gap-y-3">
                                    {data &&
                                        data?.items?.map((item, i) => (
                                            <div key={i}>
                                                {item?.anchorTag ? (
                                                    <a
                                                        href={item?.link}
                                                        target="_blank"
                                                        className="w-fit flex font-normal items-center gap-x-2 text-xs md:text-sm text-gray-300"
                                                        rel="noreferrer"
                                                    >
                                                        {item?.name}
                                                    </a>
                                                ) : (
                                                    <Link
                                                        to={
                                                            item?.loginNeed
                                                                ? isAuth ||
                                                                  Cookies.get(
                                                                      "ayykori"
                                                                  )
                                                                    ? item?.link
                                                                    : "/login"
                                                                : item?.link
                                                        }
                                                        className="w-fit flex font-normal items-center gap-x-2 text-xs md:text-sm text-gray-300"
                                                    >
                                                        {item?.name}
                                                    </Link>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="pb-20 pt-4 md:pb-4 w-full px-3 md:px-[55px] border-gray-600 flex flex-col items-center gap-y-4 border-t">
                {/* <div className="flex flex-row flex-wrap items-center gap-x-8">
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/brands/topnotch-cashbacks"}
          >
            Topnotch Cashbacks
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/affiliate"}
          >
            Share & Earn
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/"}
          >
            Know inside Out
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/brands/refer-and-earn"}
          >
            Refer & Earn
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/customer-support"}
          >
            Customer Support
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/faqs"}
          >
            FAQs
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/contact"}
          >
            Contact Us
          </Link>
          <Link
            className="text-sm text-gray-400 font-medium font-outfit "
            to={"/how-to-use-ayykori"}
          >
            How to use Ayykori
          </Link>
        </div> */}
                <div className="flex gap-y-2 flex-col justify-center items-center">
                    <div className="flex items-center gap-x-4">
                        <a
                            href="https://www.instagram.com/ayykori"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AiOutlineInstagram className="text-white text-2xl md:text-3xl" />
                        </a>
                        <a
                            href="https://www.facebook.com/ayykori.ltd"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <BsFacebook className="text-white text-xl md:text-2xl" />
                        </a>
                        <a
                            href="https://www.youtube.com/@AyyKoriDigitalLtd/featured"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AiFillYoutube className="text-white text-2xl md:text-3xl" />
                        </a>
                        <a
                            href="https://www.tiktok.com/@ayykori?lang=en"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="w-5 h-5 md:w-6 md:h-6 rounded-full p-1 bg-white"
                                src={tictok}
                                alt=""
                            />
                        </a>
                    </div>
                    <p className="text-gray-300 text-xs md:text-sm font-normal">
                        Copyright @ {new Date().getFullYear()} - All Rights
                        Reserved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
